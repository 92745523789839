@font-face {
  font-family: "Eurostyle Bold";
  src: url("./eurostyle.woff2");
}

@font-face {
  font-family: "Eurostyle";
  src: url("./eurostyle_bold.woff2");
}
@font-face {
  font-family: "Myriad";
  src: url("./MYRIADPRO-REGULAR.woff2");
}

:root {
  --header-height: 102px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* letter-spacing: -1px; */
}

html,
body {
  text-transform: uppercase;
  background-color: rgb(255, 255, 255);
  min-height: 100% !important;
  height: 100%;
  font-family: "Eurostyle";
  letter-spacing: -1px;
  font-size: 21px;
}
.contact {
  width: 100vw;
  height: 100vh;

  background-color: black;
  color: black;
}
.temps {
  font-family: "Eurostyle Bold";
}
.contact img {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}
.sideBar {
  height: calc(100vh - var(--header-height));
  display: grid;
  grid-template-rows: 2fr 1fr 1fr;
}
.sideBar video {
  width: 100%;
}
.positions {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 3px;
}
.week {
  font-size: 30px;
  padding-left: 3px;
  padding-bottom: 5px;
  line-height: 35px;
  border-bottom: 2px solid black;
  box-sizing: border-box;
}
.contact-button-wrapper {
  position: relative;
  width: 100%;
}
.contact-button {
  text-align: center;
  bottom: 0.5px;

  line-height: 50px;
  position: absolute;
  padding: 20px;
  width: 100%;
  border-radius: 100%;
  border: 2px solid black;
}
.weather {
  font-size: 21px;
  margin-left: 3px;
}
.contact .back-button {
  font-size: 18px;
  font-family: "Eurostyle Bold";
  z-index: 20;
  color: black;
  text-align: center;
  z-index: 20;
  cursor: pointer;
  position: absolute;
  border: 2px solid black;
  border-radius: 100%;
  padding: 10px;
}
.back-button-wrapper {
  z-index: 20;
  bottom: 40px;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imprint p {
 
  margin-bottom: 20px;
  
}
.imprint {
  text-transform: none;
  font-family: "Myriad";
  padding: 30px;
}
.imprint h1 {
  font-family: "Eurostyle";
}
.imprint br {
  content: "";
  font-size: 0%;
  display: block;
  line-height: 10px;
  height: 5px;
}
#header-desktop {
  width: 100%;
  position: relative;
  height: fit-content;
  border-bottom: 2px solid black;
}

#header-desktop a {
  display: grid;
  grid-template-columns: 3.4fr 0.4fr;
  width: 100%;
  position: relative;
  height: fit-content;
}
.greeter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  color: white;
}

.greeter video {
  height: 20%;
  max-height: 100%;
  margin: -1px;
  z-index: 2;
  border: 1px solid white;
  background-color: white;
  color: white;
  box-shadow: none;
  image-rendering: crisp-edges;
  box-shadow: 0 0 10px 10px white;
}
/* animation: breathing 8s ease-in-out infinite normal; */

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.greeter > h {
  position: absolute;
  bottom: 20px;
  color: rgb(206, 206, 206);
}

#header-font {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  max-height: 100px;
}

#header-logo {
  padding: 6px 0;
  display: none;
  position: relative;
  max-height: auto;
  object-fit: cover;
  max-height: 100px;
}

#logo-container {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100px;

  height: 100%;
}

header img {
  margin-right: 20px;
  float: right;
  display: inline;
  height: var(--header-height);
}

.border-right {
  border-right: 2px black solid;
}

a {
  text-decoration: none;
  color: black;
}
#header-mobile {
  margin-bottom: -10px;
  margin-top: 5px;
  width: 100%;
  position: relative;
  display: block;

  box-sizing: border-box;
}
#header-mobile a {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
#header-mobile img {
  padding: 2px 25px;

  margin: 0;
  width: 99vw;
  position: relative;
  display: unset;
}
main {
  position: relative;
  display: grid;
  height: calc(100vh - var(--header-height));
  width: 100%;
  grid-template-columns: 25px 1.5fr 2fr 0.7fr;
}

footer a {
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  letter-spacing: 1.2px;
  transform: rotate(-90deg);
  bottom: 39px;
  left: -26px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar {
  width: 2px;
  background-color: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(95, 95, 95);
}
nav::-webkit-scrollbar {
  width: 0px;
  background-color: rgb(255, 255, 255);
}
nav {
  padding: 0px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

nav a {
  font-family: "Eurostyle Bold";
  font-size: 22px !important;
  width: 100%;
  font-weight: 100;
  margin: 0px 0;
  height: 11vw;
  line-height: 11vw;
  text-align: center;
  border-radius: 100% / 105%;
  border: 2.5px black solid;
  animation: slide 0.2s ease-out;
}

@keyframes slide {
  from {
    transform: translateX(-10%);
  }
  to {
    transform: translateX(0);
  }
}

nav a:hover {
  color: rgb(112, 113, 114);
}

#contact {
  height: fit-content;
  position: absolute;
  border: 2px solid black;
  bottom: 0px;
  border-radius: 100%;
  width: 100%;
  height: 110px;
  line-height: 110px;
  text-align: center;
  margin-bottom: 2px;
  left: 0px;
}

#contact:hover {
  color: red;
}

@media screen and (min-width: 1650px) {
  nav a {
    font-size: 28px !important;
    border: 3px black solid;
    height: 180px;
    line-height: 180px;
  }

  #contact {
    height: 130px;
    line-height: 130px;
  }

  .border-right {
    border-right: 3px black solid;
  }

  #header-desktop {
    border-bottom: 3px black solid;
  }
}

#side {
  position: relative;
}

#preview,
#preview-mobile {
  height: calc(100vh - var(--header-height));
  max-height: 100% !important;
  padding: 0px;
}

#preview div,
#preview-mobile div {
  height: 100%;
  box-sizing: border-box;
  max-height: 100% !important;
  overflow: hidden;
  border: 1.8px black solid;
  border-radius: 100%;
}

#preview video,
#preview-mobile video {
  object-fit: contain;
  width: 100%;
  height: 100%;
  animation: fade 0.6s linear;
}

#social {
  width: 100%;
  margin-top: 5px;
  bottom: 0;
  height: fit-content;
  display: flex;
  justify-content: space-between;
}

#social a {
  margin-right: 5px;
}

#back {
  font-family: "Eurostyle";
  line-height: 20px;
  letter-spacing: 0.5px;
  position: absolute;
  bottom: 3.5px;
  right: 5px;
}

#artist-text {
  font-family: "Eurostyle Bold";
  position: relative;
  padding: 5px;
  display: grid;
  grid-template-rows: 1fr 40px 1.2fr 20px;
  height: calc(100vh - var(--header-height));
  overflow: hidden;
}
.artist-back{
  justify-self: end;
}
#artist-text > * {
  animation: slide-right 0.2s ease-out;
}
@keyframes slide-right {
  from {
    transform: translateX(15%);
  }
  to {
    transform: translateX(0);
  }
}
#img-container {
  width: fit-content;
  height: 100%;
  position: relative;
  display: flex;
  max-height: 100%;
  overflow: hidden;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#img-container img {
  overflow: hidden;
  max-width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
}

#artist-text #additional {
  height: 50%;
}

#artist-text #info {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

#artist-text h3 {
  font-size: 30px;
  margin-bottom: 5px;
}

#artist-text p {
  font-family: "Myriad";
  text-transform: initial;
  font-size: 24px;
}

.artist-info {
  display: grid;
  padding: 5px;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 50px 1fr;
  width: 100%;
  height: calc(100vh - var(--header-height));
}

.artist-info h1 {
  font-weight: 100;
  font-size: 36px;
  grid-column: 1/3;
}

.artist-info aside {
  padding-left: 5px;
  width: 100%;
}

#artist-pictures {
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  display: flex;
}

#ttt {
  overflow: hidden;
  box-sizing: border-box;
  width: 100wv;
}

#main-mobile {
  margin: 0;
  padding: 0;
  height: calc(100vh - var(--header-height));
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 100vw);
  animation: fade 1s;
  position: relative;
}

#main-mobile > div {
  height: calc(100vh - var(--header-height));
}

#artist-mobile-spinn {
  padding: 15px;
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  height: 100%;
  z-index: 100;
  max-height: 100% !important;
  overflow: hidden;
  padding-top: 0px;
  /*  display: flex;
  justify-content: center; */
}

#artist-mobile-spinn video {
  overflow: hidden;
  border: 3px black solid;
  border-radius: 100%;
  position: relative;
  box-sizing: border-box;
  z-index: 10;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  object-fit: cover;
  /*  animation: get_wide 0.3s ease-out; */
}
.react-swipeable-view-container {
  animation: right 1.4s cubic-bezier(1, 1.2, 0.8, 1.2);
  animation-delay: 0.4s;
  animation-play-state: paused;
}
#tutorial {
  position: absolute;
  color: transparent;
  float: right;
  right: 30px;
  transform: translateY(-50%);
  top: 50%;
  z-index: 100;
  animation: fades 5s 1.2s;
}
@keyframes fades {
  0% {
    color: transparent;
  }
  50% {
    color: black;
  }
  100% {
    color: transparent;
  }
}
.pages {
  position: static;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: calc(100vh - 100px);
  height: 20px;
  width: 100%;
}
.dot {
  border-radius: 100%;
  background-color: rgb(190, 190, 190);
  height: 7px;
  width: 7px;
  margin: 0 3px;
}
@keyframes get_wide {
  from {
    opacity: 0;
    width: 30%;
  }
  50% {
    opacity: 0;
  }
  to {
    width: 100%;
    opacity: 100;
  }
}
@keyframes right {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-112%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}
.round {
  border: 2px solid black;
  border-radius: 100%;
  line-height: 15px;
  padding: 15px;
  text-transform: lowercase;
  font-family: "Eurostyle";
  margin-bottom: 10px;
}
#mobile-text-container p {
  margin-bottom: 15px;
}
#mobile-text-container span {
  margin-bottom: 5px;
}

#artist-text-mobile {
  box-sizing: border-box;
  padding: 5px 15px;
  padding-top: 0px;
  width: 100%;
  position: relative;
  height: calc(100vh - var(--header-height));
  text-align: center;
}
#mobile-text-container {
  font-family: "Myriad";
  padding: 10px;
  text-align: left;
  min-width: none;
  margin-top: 10px;
  border: 3px solid black;
  border-radius: 20px;
}
#mobile-text-container p {
  text-transform: initial;
}
#artist-text-mobile h3 {
  font-size: 20px;
  border: 3px solid black;
  border-radius: 100%;
  line-height: 200px;
  font-family: "Eurostyle Bold";
  box-sizing: border-box;
}

#confirm-artist {
  font-family: "Eurostyle Bold";
  position: absolute;
  bottom: 20px;
  width: 99%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

#artist-info-mobile {
  box-sizing: border-box;
  width: 99%;
  padding: 10px 5px;
  position: relative;
  height: calc(100vh - var(--header-height));
}

#socials-mobile {
  position: relative;
}

#images-mobile {
  margin: auto 0;
  height: 100%;
}

#images-mobile img {
  padding: 10px 10px;
  padding-bottom: 0px;
  position: relative;
  width: 100%;

  object-fit: contain;
}

@media screen and (max-width: 845px) {
  header img {
    display: none;
  }

  header h1 {
    font-size: 100%;
  }
}

#nav-mobile {
  min-height: calc(100vh - var(--header-height));
  padding: 0 15px;
}
#mobile-socials {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: "Eurostyle Bold";
}

#nav-mobile a {
  line-height: 170px;
  height: 170px;
}
#nav-mobile a:last-child {
  text-transform: lowercase;
  margin-top: 5px;
  line-height: 105px;
  height: 105px;
  width: 60%;
  margin-bottom: 15px;
  font-family: "Eurostyle";
}
#nav-mobile a:nth-last-child(2) {
  text-transform: lowercase;
  margin-top: 30px;
  line-height: 105px;
  height: 105px;
  width: 60%;
  font-family: "Eurostyle";
}

#preview-mobile {
  min-height: 700px;
}

#preview-video-mobile {
  max-width: 700px;
  min-height: 700px;
}
@media screen and (max-width: 1240px) {
  #social #links{
    font-size: 18px;
  }
  #artist-text {
    grid-template-rows: 1fr 30px 1.2fr 18px;

  }
  #artist-text p{
    font-size: 20px;
  }
  .artist-back{
    font-size: 18px;
  }
  .weather{
    font-size: 18px;
  }
  .positions{
    font-size: 20px;
  }
}
@media screen and (max-width: 1240px){
  #artist-text {
    grid-template-rows: 1fr 44px 1.2fr 18px;

  }
}

@media screen and (max-width: 500px) {
  #nav-mobile a:last-child {
    text-transform: lowercase;
    margin-top: 5px;
    line-height: 80px;
    height: 80px;
    width: 60%;
    margin-bottom: 15px;
    font-family: "Eurostyle";
  }
  #nav-mobile a:nth-last-child(2) {
    text-transform: lowercase;
    margin-top: 30px;
    line-height: 80px;
    height: 80px;
    width: 60%;
    font-family: "Eurostyle";
  }
  #artist-text-mobile h3 {
    margin-top: 14px;
    font-size: 18px;
    border: 3px solid black;
    border-radius: 100%;
    line-height: 125px;
  }

  #nav-mobile a {
    line-height: 110px;
    height: 110px;
  }

  #preview-mobile {
    min-height: 400px;
  }

  #preview-video-mobile {
    max-width: 700px;
    min-height: 400px;
  }

  #artist-info-mobile div {
    font-size: 15.5px;
  }
}

#footer-mobile {
  padding: 0 15px !important;
  left: 0px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;

  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 5px;
  padding-bottom: 2px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.full-height {
  height: 100%;
}
